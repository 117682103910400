import type { ComboboxProps } from '@venncity/block';
import { ComboBox } from '@venncity/block';
import axios from 'axios';
import { debounce } from 'lodash-es';
import { useAsyncList } from 'react-stately';

interface AsyncComboBoxProps<T extends object> extends ComboboxProps<T> {
  resource: string;
  params?: Record<string, unknown>;
}

export default function AsyncComboBox<T extends object>({
  resource,
  children,
  isDisabled,
  defaultSelectedKey = '',
  params = {},
  ...props
}: AsyncComboBoxProps<T>) {
  const list = useAsyncList<T>({
    async load({ filterText }) {
      if (isDisabled) {
        return { items: [] };
      }
      const { data } = await axios.get(`/resources/${resource}`, {
        params: { q: filterText, include: defaultSelectedKey || undefined, ...params },
      });
      return { items: data || [] };
    },
  });

  return (
    <ComboBox
      {...props}
      defaultSelectedKey={defaultSelectedKey}
      isDisabled={isDisabled}
      items={list.items}
      onInputChange={debounce(list.setFilterText, 400, {
        maxWait: 1000,
        leading: false,
        trailing: true,
      })}>
      {children}
    </ComboBox>
  );
}
